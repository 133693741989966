import * as React from "react";
import { PersonHeaderInterface } from "./header";

export default function Footer() {
    return (
            <div className="footer">
                <PersonFooter
                    person="Jack"
                    website="https://totallyjackearl.gay/"
                    twitter="https://twitter.com/TotallyJackEarl"
                    youtube="https://www.youtube.com/@TotallyJackEarl"
                />
                <PersonFooter
                    person="Keegan"
                    website="https://keeganshut.com/"
                    twitter="https://twitter.com/keeganlippert"
                    youtube="https://www.youtube.com/@KeeganHut"
                />
            </div>
    );
}
function PersonFooter(params: PersonHeaderInterface) {
    const properties = { ...params };
    return (
        <div className={`footer-section ${properties.person}`}>
            <div className="footer-name">{properties.person}:</div>
            <a target="_blank" rel="noreferrer" href={properties.website}>
                <i className="fa-solid fa-globe" />
            </a>
            <a target="_blank" rel="noreferrer" href={properties.twitter}>
                <i className="fa-brands fa-twitter" />
            </a>
            <a target="_blank" rel="noreferrer" href={properties.youtube}>
                <i className="fa-brands fa-youtube" />
            </a>
        </div>
    );
}
