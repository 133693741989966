import * as React from 'react';
import { ProjectTypes, getTitle } from "../theBuilder";
import BuildLinks from './buildLinks'
import BuildRightSide from './buildRightSide';
import BuildCredits from './buildCredits';

export default function BuildProject(project: ProjectTypes) {
    return (
        <div
            className={`igwan-project ${project.type}`}
            key={project.name}
        >
            <div className="type-and-tags">
                <div className={`type type-${project.type}`}>{getTitle(project.type)}</div>
                <div className="tags">{project.tags}</div>
            </div>
            <div className={`project-two-column ${project.type}`}>
                <div className="project-left">
                    <div className="project-name">{project.name}</div>
                    <div className="project-date-and-desc">
                        <i>{project.date}</i> - {project.description}
                    </div>
                    <BuildLinks {...project} />
                </div>
                <div className="project-right">
                    <BuildRightSide {...project} />
                </div>
            </div>
            <BuildCredits {...project}/>
        </div>
    );
}