import * as React from 'react';
import Header from './components/header';
import TheBuilder from './components/theBuilder';
import "./style/main.scss";
import "./style/header.scss";
import "./style/footer.scss";
import Footer from './components/footer';


function App() {
  const [projectData, setProjectData] = React.useState([]);
  return (
    <div className="App">
      <Header state={{projectData, setProjectData}}/>
      <TheBuilder state={{projectData, setProjectData}}/>
      <Footer />
    </div>
  );
}

export default App;
