import * as React from "react";
import data from "./object.json";
import BuildProject from "./theBuilderComponents/buildProject";
export interface ProjectBaseInterface {
    id: string;
    name: string;
    tags: [];
    date: string;
    description: string;
    thumbnail: string;
    credits?: string
}
export interface ProjectPodcastInterface extends ProjectBaseInterface {
    type: "pod-episode";
    descriptionTwo: string;
    amazonLink?: string;
    appleLink?: string;
    spotifyLink?: string;
    rssLink: string;
    rssFeed: string;
}
export interface ProjectVideoInterface extends ProjectBaseInterface {
    type: "video";
    altImage: string;
    videoId: string;
}
export type ProjectTypes = ProjectPodcastInterface | ProjectVideoInterface;
interface TheBuilderInterface {
    state: {
        projectData: any[];
        setProjectData: React.Dispatch<React.SetStateAction<any[]>>;
    };
}
export default function TheBuilder({ state }: TheBuilderInterface) {
    const [projects, setProjects] = React.useState<JSX.Element[] | null>(null);
    React.useEffect(() => {
        console.log(data);
        state.setProjectData(data.reverse());
    }, []);

    React.useEffect(() => {
        if (state.projectData.length !== 0) {
            let projectsArray: JSX.Element[] = [];
            for (const project of state.projectData) {
                projectsArray.push(BuildProject(project));
            }
            setProjects(projectsArray);
        }
    }, [state.projectData]);
    return (
        <div id="igwan-insert-point">
            {checkIfSameType(state.projectData) ? <h1>{getTitle(state.projectData[0].type)}</h1> : ""}
            {projects}
        </div>
    );
}
export function checkIfSameType(data: any[]) {
    if(data.length === 0) return false
    for (const element of data) {
        if (element.type !== data[0].type) return false;
    }
    return true;
}
export function getTitle(type: string) {
    switch (type) {
        case "pod-episode":
            return "Podcast Episodes";
        case "video":
            return "Sketches";
        default:
            break;
    }
}
