import * as React from "react";
import data from "./object.json";

interface HeaderInterface {
    state: {
        projectData: any[];
        setProjectData: React.Dispatch<React.SetStateAction<any[]>>;
    };
}
export default function Header({ state }: HeaderInterface) {
    return (
        <>
            <nav>
                <img
                    src="/branding/IGWAN-Logo-Rectangle-web.webp"
                    alt="I Guess We're Adults Now"
                    onClick={() => state.setProjectData(data)}
                />
                <div className="navigation-menu">
                    <div
                        className="navigation-item"
                        onClick={() => state.setProjectData(data.filter((project) => project.type === "video"))}
                    >
                        Sketches
                    </div>
                    <div
                        className="navigation-item"
                        onClick={() => state.setProjectData(data.filter((project) => project.type === "pod-episode"))}
                    >
                        TPWOOE
                    </div>
                    <PersonHeader
                        person="Jack"
                        website="https://totallyjackearl.gay/"
                        twitter="https://twitter.com/TotallyJackEarl"
                        youtube="https://www.youtube.com/@TotallyJackEarl"
                    />
                    <PersonHeader
                        person="Keegan"
                        website="https://keeganshut.com/"
                        twitter="https://twitter.com/keeganlippert"
                        youtube="https://www.youtube.com/@KeeganHut"
                    />
                </div>
            </nav>
        </>
    );
}
export interface PersonHeaderInterface {
    person: "Jack" | "Keegan";
    website: string;
    twitter: string;
    youtube: string;
}
function PersonHeader(params: PersonHeaderInterface) {
    const properties = { ...params };
    const [expanded, setExpanded] = React.useState<boolean>(false);

    return (
        <>
            <div
                className={`navigation-item person ${properties.person}`}
                onClick={() => setExpanded(!expanded)}
            >
                {properties.person}
            </div>
            <div className={`person-submenu ${properties.person} ${expanded ? "expanded" : ""}`}>
                <a target="_blank" rel="noreferrer" href={properties.website}>
                    <i className="fa-solid fa-globe" />
                </a>
                <a target="_blank" rel="noreferrer" href={properties.twitter}>
                    <i className="fa-brands fa-twitter" />
                </a>
                <a target="_blank" rel="noreferrer" href={properties.youtube}>
                    <i className="fa-brands fa-youtube" />
                </a>
            </div>
        </>
    );
}
