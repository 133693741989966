import * as React from "react";
import { ProjectTypes } from "../theBuilder";
interface BuildCreditsInterface {
    project: ProjectTypes;
}
export default function BuildCredits(project: ProjectTypes) {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    if (!project.credits) return <></>;
    let creditsString = project.credits;
    let creditsArray = creditsString.split(`\n`);
    let creditsElement: JSX.Element[] = [];
    for (const credit of creditsArray) {
        const splitCredit = credit.split(":");
        creditsElement.push(
            <>
                <div className={`individual-credit`}>
                    <strong>{splitCredit[0]}:</strong>
                    {splitCredit[1]}
                </div>
            </>
        );
    }
    return (
        <>
            <div
                className="view-credits"
                onClick={() => setExpanded(!expanded)}
            >
                {expanded ? "Hide" : "View"} Credits
                <i className={`fa-solid fa-arrow-down ${expanded ? "fa-rotate-180" : ''}`}></i>
            </div>
            <div className={`credits  ${expanded ? "expanded" : ""}`}>{creditsElement}</div>
        </>
    );
}
