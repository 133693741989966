import * as React from 'react';
import { ProjectTypes } from "../theBuilder";

export default function BuildRightSide(project: ProjectTypes) {
    switch (project.type) {
        case "pod-episode":
            return (
                <>
                    <a
                        target="_blank"
                        href={project.rssLink}
                        rel="noreferrer"
                    >
                        <img
                            className={project.type}
                            src={project.thumbnail}
                            alt={`${project.name} Thumbnail`}
                        />
                    </a>
                </>
            );
        case "video":
            return (
                <>
                    <a
                        target="_blank"
                        href={`https://youtu.be/${project.videoId}`}
                        rel="noreferrer"
                    >
                        <img
                            src={project.thumbnail}
                            alt={`${project.name} Thumbnail`}
                        />
                    </a>
                </>
            );
    }
}
