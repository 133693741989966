import * as React from "react";
import { ProjectTypes } from "../theBuilder";

export default function BuildLinks(project: ProjectTypes) {
    const [buttonClicked, setButtonClicked] = React.useState(false);
    switch (project.type) {
        case "pod-episode":
            return (
                <div className="project-links">
                    <div>
                        {project.appleLink ? (
                            <a href={project.appleLink}>
                                <button>
                                    <i className="fa-brands fa-apple" />
                                    Apple Podcasts
                                </button>
                            </a>
                        ) : (
                            ""
                        )}
                        {project.spotifyLink ? (
                            <a href={project.spotifyLink}>
                                <button>
                                    <i className="fa-brands fa-spotify" />
                                    Spotify
                                </button>
                            </a>
                        ) : (
                            ""
                        )}
                        {project.rssLink ? (
                            <a href={project.rssLink}>
                                <button>
                                    <i className="fa-solid fa-rss" />
                                    RSS Link
                                </button>
                            </a>
                        ) : (
                            ""
                        )}
                    </div>
                    <div>
                        {project.rssFeed ? (
                            <>
                                <input
                                    type="text"
                                    readOnly
                                    value={project.rssFeed}
                                />
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(project.rssFeed);
                                        setButtonClicked(true);
                                        setTimeout(() => {
                                            setButtonClicked(false);
                                        }, 2000);
                                    }}
                                >
                                    {buttonClicked ? (
                                        <>
                                            <i className="fa-solid fa-check"></i>COPIED
                                        </>
                                    ) : (
                                        <>
                                            <i className="fa-regular fa-copy"></i>COPY
                                        </>
                                    )}
                                </button>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            );

        case "video":
            return (
                <>
                    <div className="project-links">
                        <a href={`https://youtu.be/${project.videoId}`}>
                            <button>
                                <i className="fa-brands fa-youtube" />
                                Watch on YouTube
                            </button>
                        </a>
                    </div>
                </>
            );
    }
}
